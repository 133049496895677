import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import { Plan } from '~/types/subscriptions'

export const signupSchema = z.object({
  // Step 1
  plan: z.nativeEnum(Plan),
  subscriptionUserSeats: z.coerce.number().int().positive().min(1).max(50),

  // Step 2
  email: z
    .string()
    .email()
    .transform(val => val.toLowerCase()),
  newsletter: z.boolean(),
  termsAgreement: z
    .boolean()
    .refine(val => val === true, { message: 'You must agree to the Terms of Service and Privacy Policy to proceed.' }),
  autoRenewalAcknowledgement: z
    .boolean()
    .refine(val => val === true, { message: 'You must agree to the Terms of Service and Privacy Policy to proceed.' })
})

export type SignupFormData = z.infer<typeof signupSchema>

// NOTE: this resolver currently only supports returning the 'first' error message per field, i.e. like
//
// {
//   "errors": {
//       "termsAgreement": {
//           "message": "You must agree to the Terms of Service and Privacy Policy to proceed.",
//           "type": "custom"
//       }
//   },
//   "defaultValues": {
//       "plan": "individual-monthly",
//       "subscriptionUserSeats": 2,
//       "email": "tsrtinrsitnrs@ntisrntisrn.com",
//       "newsletter": true,
//       "termsAgreement": true,
//       "autoRenewalAcknowledgement": true
//   }
// }
export const resolver = zodResolver(signupSchema)
